import React, { useContext } from 'react'
import { banner_image_phones, logo } from '../../assets/images'
import { UserContext } from '../../App';

function Header() {
    const user = useContext(UserContext);
  return (
    <header id="web_header">
		
    <nav>
        <div className="container_wrap">
            <div className="container_inner">
                <div className="web_logo">
                <a href="/">
                    <img src={logo} alt="Home" />							 
                </a>
            </div>
                <div className="web_header_btns df aic">
                    <ul className="dif aic jcfe">
                        <li><a style={{padding:"8px"}} href="https://lotus365.co/" target="_blank">
                            {/*<i className="zmdi zmdi-lock-outline mob_only"></i> */}
                            <span className="tab mob_only Login_btn">Login</span>
                            <span className="tab_desktop_only">Login</span></a></li>
                        <li><a href="https://lotus365.co/" target="_blank"><i className="zmdi zmdi-account-add mob_only"></i> <span className="tab_desktop_only">Register</span></a></li>
                        <li><a href="https://qrco.de/bdpfBo"><i className="zmdi zmdi-download mob_only"></i> <span className="tab_desktop_only">Download Apk</span></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </nav>
    
    <div className="banner_content">
        <div className="container_wrap">
            <div className="container_inner">
                
                <div className="banner_text">
                    <h3>POWERED BY LOTUS365</h3>
                    <h2>Download Apk for Android &amp; iOS Mobile Devices</h2>
                    {/*<div className="app_ratings df aic">*/}
                        {/*<h4>Free Latest Version</h4>*/}
                        {/*<div className="app_stars">*/}
                        {/*    <i className="zmdi zmdi-star"></i>*/}
                        {/*    <i className="zmdi zmdi-star"></i>*/}
                        {/*    <i className="zmdi zmdi-star"></i>*/}
                        {/*    <i className="zmdi zmdi-star"></i>*/}
                        {/*    <i className="zmdi zmdi-star"></i>*/}
                        {/*</div>*/}
                        {/* <span>Last update on: 5 Apr 2023</span> */}
                    {/*</div>*/}
                    <div className="officialwebsite">
                        <h4>Our Official Website is
                        </h4>
                        <a className="blinkAnimation" target={"_blank"} href="https://lotus365.co/">www.lotus365.co</a>
                    </div>
                    <ul className="app_btns df aic">
                        <li>
                            <a href="https://qrco.de/bdpfBo" className="df aic"><i className="zmdi zmdi-android-alt"></i> <span>ANDROID <b className="desktop_only">APK</b></span></a>
                        </li>
                        <li>
                            <a href="#apple_app_tab" onClick={() => user.handleTabClick(1)} className="df aic"><i className="zmdi zmdi-apple"></i> <span>IOS <b className="desktop_only">APP</b></span></a>
                        </li>
                    </ul>
                </div>
                
                <div className="banner_phone_img">
                    <img  src={banner_image_phones} alt="phones" />
                </div>
                
            </div>
        </div>
    </div>
    
    
</header>
  )
}

export default Header
