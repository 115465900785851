import React, { useContext, useState } from 'react'
import { 
    ad_b1,
    ad_b2,
    ad_b3,
    ad_b4,
    ad_b5,
    ad_b6,
    ios_app_0,
    ios_app_1,
    ios_app_2,
    ios_app_3,
    ios_app_4,
    ios_app_5
 } from "./../../assets/images";
import { UserContext } from '../../App';

function AppTabs() {
    const user = useContext(UserContext);
    
    
  return (
    <section id="app_tabs" className="sec_padding">
    <div className="container_wrap">
        <div className="sec_title">
            <h2>How to install the application?</h2>
            <p>Let’s have a look at how to install the application on your device</p>
        </div>
        
        <ul className="app_btns df aic jcc app_tabs">
            <li>
                <a  className={ user.activeTab === 0 ? 'df aic active_tab_trigger' : 'df aic'}
      onClick={() => user.handleTabClick(0)}>
                    <i className="zmdi zmdi-android-alt"></i> <span>ANDROID <b className="desktop_only">APK</b></span></a>
            </li>
            <li>
                <a   className={ user.activeTab === 1 ? 'df aic active_tab_trigger' : 'df aic'}
      onClick={() =>  user.handleTabClick(1)}>
                    <i className="zmdi zmdi-apple"></i> <span>IOS <b className="desktop_only">APP</b></span></a>
            </li>
        </ul>
        
        <div className="tabs_content">
            
            <div id="android_app_tab"  className={`single_tab ${ user.activeTab === 0 ? 'active_tab' : ''}`}>
                <div className="container_inner fww">
                    <div className="c_33p">
                        <div className="tab_box ad_box">
                            <div className="tab_box_inner">
                                <span className="box_no">1</span>
                                <img src={ad_b1} alt="Step 1" />
                            </div>
                            <h3>Download from website</h3>
                            <p>You can follow the lotus365.co to download your latest application instant and enjoy our services</p>
                        </div>
                    </div>
                    
                    <div className="c_33p">
                        <div className="tab_box ad_box">
                            <div className="tab_box_inner">
                                <span className="box_no">2</span>
                                <img src={ad_b2} alt="Step 2" />
                            </div>
                            <h3>Confirm</h3>
                            <p>Confirm the Lotus365 download apk, if needed.</p>
                        </div>
                    </div>
                    
                    <div className="c_33p">
                        <div className="tab_box ad_box">
                            <div className="tab_box_inner">
                                <span className="box_no">3</span>
                                <img src={ad_b3} alt="Step 3" />
                            </div>
                            <h3>Device settings</h3>
                            <p>Open your mobile device settings. Give permission to install apps downloaded from unknown sources.</p>
                        </div>
                    </div>
                    
                    <div className="c_33p">
                        <div className="tab_box ad_box">
                            <div className="tab_box_inner">
                                <span className="box_no">4</span>
                                <img src={ad_b4} alt="Step 4" />
                            </div>
                            <h3>Downloads folder</h3>
                            <p>Open the "Downloads" folder. Tap on the downloaded Lotus365 apk.</p>
                        </div>
                    </div>
                    
                    <div className="c_33p">
                        <div className="tab_box ad_box">
                            <div className="tab_box_inner">
                                <span className="box_no">5</span>
                                <img src={ad_b5} alt="Step 5" />
                            </div>
                            <h3>Install</h3>
                            <p>Tap on "Install".</p>
                        </div>
                    </div>
                    
                    <div className="c_33p">
                        <div className="tab_box ad_box">
                            <div className="tab_box_inner">
                                <span className="box_no">6</span>
                                <img src={ad_b6} alt="Step 6" />
                            </div>
                            <h3>Success</h3>
                            <p>The app has been installed.</p>
                        </div>
                    </div>
                    
                </div>	
            </div>
            
            <div id="apple_app_tab"  className={`single_tab ${ user.activeTab === 1 ? 'active_tab' : ''}`}>
                <div className="container_inner fww">
                    <div className="c_33p">
                        <div className="tab_box ap_box">
                            <div className="tab_box_inner">
                                <span className="box_no">1</span>
                                <img src={ios_app_0} alt="Step 1" />
                                <h3>Open Safari Browser</h3>
                                <p>Open Safari Browser on your IOS device.</p>
                            </div>
                            
                        </div>
                    </div>
                    
                    <div className="c_33p">
                        <div className="tab_box ap_box">
                            <div className="tab_box_inner">
                                <span className="box_no">2</span>
                                <img src={ios_app_1} alt="Step 1" />
                                <h3>Lotus 365 website</h3>
                                <p>Open website on Safari browser and click on share button.</p>
                            </div>
                            
                        </div>
                    </div>
                    
                    <div className="c_33p">
                        <div className="tab_box ap_box">
                            <div className="tab_box_inner">
                                <span className="box_no">3</span>
                                <img src={ios_app_2} alt="Step 1" />
                                <h3>Select</h3>
                                <p>Select Add to Home Screen option</p>
                            </div>
                            
                        </div>
                    </div>
                    
                    <div className="c_33p">
                        <div className="tab_box ap_box">
                            <div className="tab_box_inner">
                                <span className="box_no">4</span>
                                <img src={ios_app_3} alt="Step 1" />
                                <h3>Rename</h3>
                                <p>Rename the shortcut.</p>
                            </div>
                            
                        </div>
                    </div>
                    
                    <div className="c_33p">
                        <div className="tab_box ap_box">
                            <div className="tab_box_inner">
                                <span className="box_no">5</span>
                                <img src={ios_app_4} alt="Step 1" />
                                <h3>After installation</h3>
                                <p>Check home screen for created Lotus365 shortcut & tap to open</p>
                            </div>
                            
                        </div>
                    </div>
                    
                    <div className="c_33p">
                        <div className="tab_box ap_box">
                            <div className="tab_box_inner">
                                <span className="box_no">6</span>
                                <img src={ios_app_5} alt="Step 1" />
                                <h3>Open</h3>
                                <p>After opening you are ready to use the created shortcut.</p>
                            </div>
                            
                        </div>
                    </div>
                    
                </div>	
            </div>
            
            
        </div>
        
        
    </div>	
</section>
  )
}

export default AppTabs
