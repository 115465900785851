import logo from './logo.svg';
import './App.css';
import "./style/style.css";
import { Home } from './pages';
import { createContext, useState } from 'react';

export const UserContext = createContext();

function App() {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
      setActiveTab(index);
    }
  const contextValues = {
    activeTab:activeTab,
    handleTabClick:handleTabClick
  };
  return (
    <UserContext.Provider value={contextValues}>
    <div className="App">
     <Home/>
    </div>
    </UserContext.Provider>
  );
}

export default App;
