import React, { useContext } from 'react'
import { apple_itunes_btn, footer_img1, footer_img2, footer_img3, footer_img4, footer_img5, footer_img6, gc_logo, google_play_btn, insta, telegram, whatsapp } from '../../assets/images'
import { UserContext } from '../../App';

function Footer() {
	const user = useContext(UserContext);
  return (
    <footer id="web_footer">	
		
		<div className="container_wrap add_b_c">
			<div className="add_banner">
				<div className="container_inner c_grid_m">
					<div className="add_content c_50p">
						<h2>Keep games world into your pockets</h2>
						<p>Let’s download out wonderfull application with all the games that you want to play</p>
					</div>
					<div className="add_buttons_img df aic c_50p">
						<ul className="df aic">
							<li className="android_app_btn_li">
								<a href="https://qrco.de/bdpfBo">
									<img src={google_play_btn} alt="Google Play" />
								</a>
							</li>
							<li className="apple_app_btn_li">
								<a href="#apple_app_tab" onClick={() => user.handleTabClick(1)}>
									<img src={apple_itunes_btn} alt="Apple itunes" />
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		
		<div className="container_wrap">
			<div className="social_contact_bar df jcc aic">
				<div className="scb">
					<a target="_blank"><img src={gc_logo} alt="GC" /></a>
				</div>
				<div className="scb">
					<a href="http://wa.link/lotus365" target="_blank"><img src={whatsapp} alt="whatsapp" /></a>
				</div>
				<div className="scb df jcc aic">
					<a href="https://www.instagram.com/lotus365world/" target="_blank"><img src={insta} alt="insta" /></a>
					<a href="https://telegram.me/lotus365" target="_blank"><img src={telegram} alt="telegram" /></a>
				</div>
			</div>
			
			<div className="footer_content">
				<span>LOTUS 365 is a limited liability company incorporated under the laws of Curacao Players are requested not to contact any untrusted sources for LOTUS 365 accounts as this is an online site and they can only register independently without any agents.</span><span> Only deposit through the account details generated by the system or provided by our official support team.</span>
			</div>
			
			<div className="footer_p_logos df aic jcc">
				<img src={footer_img1} alt="" />
				<img src={footer_img2} alt="" />
				<img src={footer_img3} alt="" />
				<img src={footer_img4} alt="" />
				<img src={footer_img5} alt="" />
				<img src={footer_img6} alt="" />
			</div>
		
			<div className="footer_bottom">
				<p>
					Rules &amp; Regulations © 2016-2023 v. 9.56 <span className="sep_span">|</span> <a href="https://vimeo.com/752093924" target="_blank">Introduction</a><span className="sep_span">-</span><a href="https://vimeo.com/751610667" target="_blank">How to register</a>  <span className="sep_span">-</span><a href="https://vimeo.com/751698929" target="_blank">How to deposit</a><span className="sep_span">-</span><a href="https://vimeo.com/751686294" target="_blank">How to use bonus</a>
				</p>
			</div>
			
		</div>
			
		
	</footer>
  )
}

export default Footer