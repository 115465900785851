import React from 'react'
import { AppAdvantages, AppTabs, Footer, Header, ImageGallery, TrustedCustomers } from '../../components'

function Home() {
  return (
    <div>
        <Header/>
        <TrustedCustomers/>
        <AppAdvantages/>
        <ImageGallery/>
        <AppTabs/>
        <Footer/>
    </div>
  )
}

export default Home