import React from 'react'
import { meta_icon, person1, person2, person3, star, trophy } from '../../assets/images'

function AppAdvantages() {
  return (
    <section id="app_advantages" className="sec_padding">
		<div className="container_wrap">
			<div className="sec_title ta_left">
				<h2>Outlining the Advantages of the Lotus365 Application</h2>
				<p>Let's join forces and make this world a better place together! Connect with us to get the best experience ever!</p>
			</div>

			<div className="container_inner c_grid_m">
				<div className="c_33p">
					<div className="app_info_box">
						<div className="app_info_box_inner">
							<h4 className="df aic"><img src={star} alt="Star" /> <span>4.8 Rating</span></h4>

							<div className="members_div df aic">
								<ul className="df aic all_user_ul">
									<li><img src={person1} alt="" /></li>
									<li><img src={person2} alt="" /></li>
									<li><img src={person3} alt="" /></li>
								</ul>
								<h5>The <span>Lotus 365</span> Members</h5>
							</div>

							<div className="rb_content">
							Lotus 365 an ever-growing membership base, now totaling over +836.7K members and counting...
							</div>

							<a href="https://lotus365.co/" target='_blank' className="web_link"><span>Join our community</span> <i className="zmdi zmdi-arrow-right"></i></a>
						
						</div>
					</div>
				</div>
				
				<div className="c_33p">
					<div className="app_info_box">
						<div className="app_info_box_inner nbs">
							<h4 className="df aic"><img src={trophy} alt="Trophy" /> <span>4.8 Rating</span></h4>

							<div className="members_div df aic">
								<ul>
									<li><img src={meta_icon} alt="Meta" /></li>
								</ul>
								<h5>Updates concerning social media</h5>
							</div>

							<div className="rb_content">
							Stay in now with our most recent stories and information by following us on Instagram and Facebook. We want to make interacting with people around the world a delightful experience.
							</div>

							<a href="https://lotus365.co/" target='_blank'  className="web_link"><span>Join our community</span> <i className="zmdi zmdi-arrow-right"></i></a>
						</div>
					</div>
				</div>
				
				<div className="c_33p">
					<div className="appbox_last">
						<h3>Our Achievement</h3>
						<ul>
							<li>Rather high odds that update every second.</li>
							<li>Many tournaments for betting.</li>
							<li>Several esports are available for betting.</li>
							<li>High-security rate.</li>
							<li>Supports works 24*7.</li>
							<li>Technical requirements of the Lotus365 APK are low and the mobile app can work stably at any gadget.</li>
							<li>Possible to bet with a couple of clicks only.</li>
						</ul>
						<a href="https://qrco.de/bdpfBo" className="web_btn"><span>Download app</span> <i className="zmdi zmdi-arrow-right"></i></a>
					</div>
				</div>
			</div>
		</div>
	</section>
  )
}

export default AppAdvantages
