import React, { useContext, useRef, useState } from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import {  phone_s_shot,
    phone_s_shot1,
    phone_s_shot2,
    phone_s_shot3,
    phone_s_shot4,
    phone_s_shot5,
    phone_s_shot6,
    phone_s_shot7,
    phone_s_shot8,
    phone_s_shot9, } from "./../../assets/images";
import { UserContext } from '../../App';


function ImageGallery() {
  const user = useContext(UserContext);
    const [galleryOptions, setGalleryOptions] = useState({
        loop: true,
        margin: 24,
        responsive: {
          0: {
            items: 2,
            margin: 6,
          },
          600: {
            items: 3,
          },
          1000: {
            items: 4,
          },
          1200: {
            items: 5,
          },
          1400: {
            items: 6,
          },
        },
      });
      const owlRef = useRef(null);

      const handlePrevClick = () => {
        owlRef.current.prev();
       
      };
    
      const handleNextClick = () => {
        owlRef.current.next();
      };
  return (
    <section id="image_gallery" className="sec_padding">
      <div className="container_wrap">
        <div className="sec_title">
          <div className="sec_title_inner df aic jcsb">
            <div className="sti_left df aic">
              <h2>Image Gallery</h2>
              <span>Let’s have a look, how our app looks..!</span>
            </div>
            <ul className="gallery_slider_nav df aic">
              <li>
                <button className="gs_left" onClick={e=>handlePrevClick()}>
                  <i className="zmdi zmdi-arrow-left"></i>
                </button>
              </li>
              <li>
                <button className="gs_right" onClick={e=>handleNextClick()}>
                  <i className="zmdi zmdi-arrow-right"></i>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="container_f_wrap">
        <div className="gallery_slider_wrap">
          <OwlCarousel ref={owlRef} className="owl-theme gallery_slider_trigger" {...galleryOptions}  >
            <div className="item">
              <div className="phon_s_shot">
                <img src={phone_s_shot} alt="" />
              </div>
            </div>

            <div className="item">
              <div className="phon_s_shot">
                <img src={phone_s_shot1} alt="" />
              </div>
            </div>

            <div className="item">
              <div className="phon_s_shot">
                <img src={phone_s_shot2} alt="" />
              </div>
            </div>

            <div className="item">
              <div className="phon_s_shot">
                <img src={phone_s_shot3} alt="" />
              </div>
            </div>

            <div className="item">
              <div className="phon_s_shot">
                <img src={phone_s_shot4} alt="" />
              </div>
            </div>

            <div className="item">
              <div className="phon_s_shot">
                <img src={phone_s_shot5} alt="" />
              </div>
            </div>

            <div className="item">
              <div className="phon_s_shot">
                <img src={phone_s_shot6} alt="" />
              </div>
            </div>

            <div className="item">
              <div className="phon_s_shot">
                <img src={phone_s_shot7} alt="" />
              </div>
            </div>
            <div className="item">
							<div className="phon_s_shot">
								<img src={phone_s_shot8} alt="" />
							</div>
						</div>
						
						
						<div className="item">
							<div className="phon_s_shot">
								<img src={phone_s_shot9} alt="" />
							</div>
						</div>
						
						
					</OwlCarousel>
				</div>
		</div>
		
		<div className="container_wrap">
			<ul className="app_btns df aic jcc">
                <li>
                    <a href="https://qrco.de/bdpfBo" className="df aic"><i className="zmdi zmdi-android-alt"></i> <span>ANDROID <b className="desktop_only">APK</b></span></a>
                </li>
                <li>
                    <a href="#apple_app_tab" onClick={() => user.handleTabClick(1)} className="df aic"><i className="zmdi zmdi-apple"></i> <span>IOS <b className="desktop_only">APP</b></span></a>
                </li>
            </ul>
		</div>
    
	</section>
  )
}

export default ImageGallery