import React from 'react'
import { blank_dp, man_2, person, women_1, women_2, women_3 } from '../../assets/images'
// import './../../assets/custom.js';
import OwlCarousel from 'react-owl-carousel';

function TrustedCustomers() {
  return (
    <section id="trusted_customers" className="sec_padding">
		<div className="container_wrap">
			<div className="sec_title">
				<h2>What our trusted customer says..! </h2>
				<p>We're thrilled to hear that our customers are satisfied! Check out what they have to say!</p>
			</div>
			
			<div className="client_testimonials">
            <OwlCarousel
        className="client_testimonial_slider"
        loop
        margin={10}
        dots
        responsive={{ 0: { items: 1 } }}
      >
	<div className="item">
						<div className="single_testimonial df aic fdc">
							<div className="p_img"> 
								<img src={person} alt="Rowhan Smith" />
							</div>
							<h3 className="person_name_proffesion"><strong>Rowhan Smith</strong></h3>
							<div className="person_quote">
								<p>Lotus 365 is an incredibly easy-to-use app that makes gaming an enjoyable and thrilling experience. With countless amazing games to select from, it is an ideal choice for anyone who loves to play. I highly recommend my friends to try this one!</p>
							</div>
						</div>
					</div>
					<div className="item">
						<div className="single_testimonial df aic fdc">
							<div className="p_img">
								<img src={women_1} alt="Cheyanne Mcbride" />
							</div>
							<h3 className="person_name_proffesion"><strong>Cheyanne Mcbride </strong></h3>
							<div className="person_quote">
								<p>Setting up instant deposits and withdrawals with Lotus 365 was a breeze! I was so impressed by how quickly my deposit was processed, it only took a few clicks! I'm so pleased with the convenience of this great app! Great going team!!</p>
							</div>
						</div>
					</div>
					<div className="item">
						<div className="single_testimonial df aic fdc">
							<div className="p_img">
								<img src={man_2} alt="Joe Briggs" />
							</div>
							<h3 className="person_name_proffesion"><strong>Joe Briggs</strong></h3>
							<div className="person_quote">
								<p>I'm really pleased with the wide selection of gaming options this app offers. It provides me with the opportunity to play whatever I like, whenever and wherever I am - and the customer service is available round the clock, which is unbelievable! Truly Amazing....</p>
							</div>
						</div>
					</div>
					<div className="item">
						<div className="single_testimonial df aic fdc">
							<div className="p_img">
								<img src={women_2} alt="Aliana Crosby" />
							</div>
							<h3 className="person_name_proffesion"><strong>Aliana Crosby</strong></h3>
							<div className="person_quote">
								<p>I'm absolutely thrilled to have found out about these incredible bonus offers this app has! I was a bit worried about giving it a go, but I'm so glad I did - it ended up being such a rewarding experience! It really went above and beyond my expectations and I'm so happy that I took a chance. I highly recommend it!</p>
							</div>
						</div>
					</div>
					<div className="item">
						<div className="single_testimonial df aic fdc">
							<div className="p_img">
								<img src={women_3} alt="Taniya Holloway" />
							</div>
							<h3 className="person_name_proffesion"><strong>Taniya Holloway</strong></h3>
							<div className="person_quote">
								<p>As a first-time user of this app, I was quite understandably skeptical about how to make a deposit. But I was delighted to discover how fast and simple it was! The deposit went through without any issues and my withdrawal was even easier. It only took a few minutes to withdraw my funds! I am incredibly impressed with the entire experience!</p>
							</div>
						</div>
					</div>
</OwlCarousel>
				<div className="owl-carousel owl-theme client_testimonial_slider">
					
					<div className="item">
						<div className="single_testimonial df aic fdc">
							<div className="p_img"> 
								<img src={blank_dp} alt="Abby Goodman" />
							</div>
							<h3 className="person_name_proffesion"><strong>Abby Goodman</strong></h3>
							<div className="person_quote">
								<p>Everything is cool, but I had to strain myself to clear the bonus. As for the rest, Parimatch only pleased me so far, the deposit-withdrawal of money is instant, the truth of the amount I have is very small. Before that I only played on foreign beeches – it turned out that our fellows were also. A very handy mobile application.</p>
							</div>
						</div>
					</div>
					<div className="item">
						<div className="single_testimonial df aic fdc">
							<div className="p_img">
								<img src={blank_dp} alt="Kade Mata" />
							</div>
							<h3 className="person_name_proffesion"><strong>Kade Mata</strong></h3>
							<div className="person_quote">
								<p>Everything is cool, but I had to strain myself to clear the bonus. As for the rest, Parimatch only pleased me so far, the deposit-withdrawal of money is instant, the truth of the amount I have is very small. Before that I only played on foreign beeches – it turned out that our fellows were also. A very handy mobile application.</p>
							</div>
						</div>
					</div>
					<div className="item">
						<div className="single_testimonial df aic fdc">
							<div className="p_img">
								<img src={blank_dp} alt="Eliezer Stokes" />
							</div>
							<h3 className="person_name_proffesion"><strong>Eliezer Stokes</strong></h3>
							<div className="person_quote">
								<p>Everything is cool, but I had to strain myself to clear the bonus. As for the rest, Parimatch only pleased me so far, the deposit-withdrawal of money is instant, the truth of the amount I have is very small. Before that I only played on foreign beeches – it turned out that our fellows were also. A very handy mobile application.</p>
							</div>
						</div>
					</div>
					<div className="item">
						<div className="single_testimonial df aic fdc">
							<div className="p_img">
								<img src={blank_dp} alt="Leon Cuevas" />
							</div>
							<h3 className="person_name_proffesion"><strong>Leon Cuevas</strong></h3>
							<div className="person_quote">
								<p>Everything is cool, but I had to strain myself to clear the bonus. As for the rest, Parimatch only pleased me so far, the deposit-withdrawal of money is instant, the truth of the amount I have is very small. Before that I only played on foreign beeches – it turned out that our fellows were also. A very handy mobile application.</p>
							</div>
						</div>
					</div>
					<div className="item">
						<div className="single_testimonial df aic fdc">
							<div className="p_img">
								<img src={blank_dp} alt="Sasha Greer" />
							</div>
							<h3 className="person_name_proffesion"><strong>Sasha Greer</strong></h3>
							<div className="person_quote">
								<p>Everything is cool, but I had to strain myself to clear the bonus. As for the rest, Parimatch only pleased me so far, the deposit-withdrawal of money is instant, the truth of the amount I have is very small. Before that I only played on foreign beeches – it turned out that our fellows were also. A very handy mobile application.</p>
							</div>
						</div>
					</div>
					
					
				</div>
				
				
			</div>
			
		</div>
	</section>
  )
}

export default TrustedCustomers